import { hasFileExtension } from './hasFileExtension.js';

const imageFileExtensions = ['.png', '.jpeg', '.jpg', '.avif', '.webp', '.gif', '.jfif', '.svg'];

export const isImage = (url: string) => {
  if (!url) {
    return false;
  }
  if (url.startsWith('data:')) {
    return true;
  }
  return hasFileExtension(url, imageFileExtensions);
};
