import { isImage } from './isImage.js';
import { isPdf } from './isPdf.js';
import { isVideo } from './isVideo.js';

export const getRemoveReference = (url: string): boolean => {
  if (!url) {
    return true;
  }
  if (url && isImage(url)) {
    return false;
  }
  if (url && isVideo(url)) {
    return false;
  }
  if (url && isPdf(url)) {
    return false;
  }
  return true;
};
